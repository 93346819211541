import * as React from "react"
import { 
  Edit, 
  TabbedForm, 
  TextInput,
  BooleanInput,
  useRecordContext,
  useDataProvider,
  useNotify,
  useCreatePath,
  PasswordInput,
  email,
  AutocompleteInput,
  ReferenceInput,
} from "react-admin"
import {InputAdornment, Grid, Box, Typography, ImageList, ImageListItem, Card, CardContent, IconButton, Pagination } from "@mui/material"
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";

const MemberTitle = () => {
  return <span>{'商品'}</span>;
};

const MemberEdit = () => {
  const dataProvider = useDataProvider()
  const notify = useNotify();

  const validateForm = async (values) => {
    const errors = {}
    if (!values.lastName) {
      errors.firstName = "ra.validation.required"
    }
    if (!values.firstName) {
      errors.firstName = "ra.validation.required"
    }
    if (!values.account) {
      errors.account = "ra.validation.required"
    } else {
      const error = email()(values.account)
      if (error) {
        errors.account = error
      }
    }
    if (!values.mobile) {
      errors.mobile = "ra.validation.required"
    }
    if (values.password && values.password !== values.confirmPassword) {
      errors.confirmPassword = "resources.customers.errors.password_mismatch"
    }
  
    try {
      const { data } = await dataProvider.getList("members", {
        filter: { email: values.email, excludeId: values.id },
        pagination: { page: 1, perPage: 1 },
        sort: { field: "createdAt", order: "ASC" },
      });
      if (data.length > 0) {
        errors.email = "resources.members.errors.email_exists"
      }
    } catch (error) {
      console.error("驗證電子郵件錯誤:", error);
    }
  
    return errors
  }

  const CustomReferenceInput = ({reference, source, label }) => {
    const dataProvider = useDataProvider();

    const handleCreate = async (newName) => {
        try {
          const { data } = await dataProvider.create(reference, {
            data: { name: newName }
          });
          return { id: data.id, name: data.name };
        } catch (error) {
          console.error(`Error creating ${reference}:`, error);
        }
    };

    return (
      <ReferenceInput 
        source={source} 
        reference={reference}
        sort={{ field: 'createdAt', order: 'ASC' }}
      >
        <AutocompleteInput 
          label={label} 
          onCreate={handleCreate}
          isPending={true}
        />
      </ReferenceInput>
    );
  };

  const LogoTab = () => {
    const record = useRecordContext(); // 取得會員資訊
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const createPath = useCreatePath();
  
    const [logos, setLogos] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
  
    useEffect(() => {
      if (record) {
        fetchLogos();
      }
    }, [record, page]);
  
    // 取得該會員的 Logo
    const fetchLogos = () => {
      dataProvider
        .getList("logos", {
          filter: { memberId: record.id },
          pagination: { page, perPage: 10 }, // 每頁 10 張圖
          sort: { field: "createdAt", order: "ASC" },
        })
        .then(({ data, total }) => {
          setLogos(data);
          setTotalPages(Math.ceil(total / 10)); // 計算總頁數
        });
    };
  
    // 刪除 Logo
    const handleDelete = (id) => {
      dataProvider
        .delete("logos", { id })
        .then(() => {
          notify("Logo 刪除成功", { type: "success" });
          fetchLogos();
        })
        .catch(() => notify("刪除失敗", { type: "warning" }));
    };
  
    return (
      <div>
        {/* 顯示 Logo 清單 */}
        <ImageList cols={5} gap={16} sx={{ padding: 1 }}>
          {logos.map((logo) => (
            <ImageListItem 
              key={logo.id} 
            >
              <Link
                to={createPath({
                  resource: "logos",
                  id: logo.id,
                  type: "edit",
                })}
                style={{ display: "block", width: "100%", height: "100%" }} // 確保 Link 充滿 ImageListItem
              >
              <Card sx={{ width: 150, boxShadow: 3 }}>
                <img src={logo.imageUrl} alt="會員 Logo" style={{ width: "100%", height: 150, objectFit: "contain" }} />
                <CardContent sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                  <Typography variant="subtitle1">{logo.name || "Logo"}</Typography>
                  <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
                    {logo.description}
                  </Typography>
                </CardContent>
              </Card>
              </Link>
            </ImageListItem>
          ))}
        </ImageList>
        {/* 分頁控制 */}
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, value) => setPage(value)}
          sx={{ mt: 2, display: "flex", justifyContent: "center" }}
        />
      </div>
    );
  };

  const DesignTab = () => {
    const record = useRecordContext(); // 取得會員資訊
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const createPath = useCreatePath();
  
    const [designs, setDesigns] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
  
    useEffect(() => {
      if (record) {
        fetchDesigns();
      }
    }, [record, page]);
  
    // 取得該會員的 Design
    const fetchDesigns = () => {
      dataProvider
        .getList("designs", {
          filter: { memberId: record.id },
          pagination: { page, perPage: 8 }, // 每頁 6 張圖
          sort: { field: "createdAt", order: "ASC" },
        })
        .then(({ data, total }) => {
          setDesigns(data);
          setTotalPages(Math.ceil(total / 8)); // 計算總頁數
        });
    };
  
    return (
      <div>
        {/* 顯示 Design 清單 */}
        <ImageList cols={4} gap={16} sx={{ padding: 1 }}>
          {designs.map((design) => (
            <ImageListItem 
              key={design.id} 
            >
              <Card sx={{ width: '100%', boxShadow: 3 }}>
                <img src={design.imageUrl} alt="會員 Design" style={{ width: "100%", height: 150, objectFit: "contain" }} />
                <CardContent sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                  <Typography variant="subtitle1">{design.designName}</Typography>
                  <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
                    {design.productName}
                  </Typography>
                </CardContent>
              </Card>
            </ImageListItem>
          ))}
        </ImageList>
        {/* 分頁控制 */}
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, value) => setPage(value)}
          sx={{ mt: 2, display: "flex", justifyContent: "center" }}
        />
      </div>
    );
  };

  return (
  <Edit title={<MemberTitle/>}>
    <TabbedForm
      // Here for the GQL provider
      defaultValues={{
        code: "",
        status: true,
        lastName: "",
        firstName: "",
        account: "",
        mobile: "",
        companyName: "",
        companyRegistrationNumber: "",
        companyPhone: "",
        department: "",
        title: "",
        password: "",
        confirmPassword: "",
      }}
      validate={validateForm}
    >
      <TabbedForm.Tab
        label="基本資料"
        sx={{ maxWidth: "50em" }}
      >
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={8}>
            <TextInput source="code" label="編號" disabled />
          </Grid>
          <Grid item xs={12} sm={4} 
            sx={{
              display: "flex",
              justifyContent: "right"
            }}
          >
            <BooleanInput label="啟用狀態" source="status" />
          </Grid>
          <Grid item xs={12}>
            <TextInput type="account" source="account" label="帳號" disabled />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source="lastName" label="姓氏" isRequired />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source="firstName" label="名字" isRequired />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput type="email" source="email" label="電子郵件" autoComplete="off" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source="mobile" label="手機" isRequired autoComplete="off" />
          </Grid>
          <Grid item xs={12}>
            <TextInput source="description"
              label="備註" 
              multiline
              rows={4} // 設定多行的行數
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {'更改密碼'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <PasswordInput source="password" label="密碼" autoComplete="new-password" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PasswordInput source="confirmPassword" label="再次輸入密碼" autoComplete="new-password" />
          </Grid>
        </Grid>
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="公司資訊"
        path="company"
        sx={{ maxWidth: "50em" }}
      >
        <Grid container columnSpacing={2}>
          <Grid item xs={12}>
            <TextInput source="companyName" label="公司名稱" />
          </Grid>
          <Grid item xs={12} sm={6}>
            < CustomReferenceInput label="公司規模" source="companySizeId" reference="company-sizes" />
          </Grid>
          <Grid item xs={12} sm={6}>
            < CustomReferenceInput label="產業別" source="industryId" reference="industries" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source="companyRegistrationNumber" label="公司統編" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source="companyPhone" label="公司電話" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source="department" label="部門" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source="title" label="職稱" />
          </Grid>
        </Grid>
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="上傳LOGO"
        path="logos"
        sx={{ maxWidth: "50em" }}
      >
        <LogoTab />
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="設計庫"
        path="designs"
        sx={{ maxWidth: "50em" }}
      >
        <DesignTab />
      </TabbedForm.Tab>
    </TabbedForm>
  </Edit>
  )
}

export default MemberEdit