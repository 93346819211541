import * as React from "react"
import {
  BooleanField,
  CreateButton,
  DatagridConfigurable,
  DateField,
  List,
  SearchInput,
  TopToolbar,
  TextField,
  useListContext,
  DeleteButton,
  useRecordContext,
  ImageField,
  DeleteWithConfirmButton
} from "react-admin"
import { useMediaQuery, Button, Box, Typography } from "@mui/material"
import ContentFilter from '@mui/icons-material/FilterList';

import LogoFilterForm from "./LogoFilterForm"

const TruncatedTextField = ({ source, maxLength = 30 }) => {
  const record = useRecordContext();
  const text = record?.[source] || '';
  const truncatedText = text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

  return (
    <Typography variant="body2" title={text}>
      {truncatedText}
    </Typography>
  );
};

const LogoFilterButton = () => {
  const { showFilter } = useListContext()
  return (
    <Button
      size="small"
      color="primary"
      onClick={() => showFilter("main")}
      startIcon={<ContentFilter />}
      sx={{
        height: '27.5px', // 調整按鈕高度
        padding: '4px 5px', // 調整內邊距
        fontSize: '13px', // 調整字型大小，這樣可以與 CreateButton 大小對齊
      }}
    >
      篩選
    </Button>
  )
}

const visitorFilters = [
  <SearchInput source="q" alwaysOn />,
  // <DateInput source="last_seen_gte" />,
  // <NullableBooleanInput source="has_ordered" />,
  // <NullableBooleanInput source="has_newsletter" defaultValue />,
  // <SegmentInput source="groups" />
]

const ContactListActions = () => (
  <Box width="100%">
    <TopToolbar>
      <LogoFilterButton />
      <CreateButton />
      {/* <SelectColumnsButton /> */}
      {/* <ExportButton /> */}
    </TopToolbar>
    <LogoFilterForm />
  </Box>
)

const LogoTitle = () => {
  return <span>{'LOGO'}</span>;
};

const getStatusColor = (status) => {
  switch (status) {
    case 'pending':
      return 'blue';
    case 'approved':
      return 'green';
    case 'rejected':
      return 'red';
    default:
      return 'black';
  }
};

const LogoList = () => {
  const isXsmall = useMediaQuery(theme => theme.breakpoints.down("sm"))
  const isSmall = useMediaQuery(theme => theme.breakpoints.down("md"))
  return (
    <List
      title={<LogoTitle/>}
      filters={isSmall ? visitorFilters : undefined}
      sort={{ field: "createdAt", order: "DESC" }}
      perPage={25}
      // aside={<ContactListAside />}
      actions={<ContactListActions />}
    >
      {isXsmall ? (
        // <MobileGrid />
        <div></div>
      ) : (
        <DatagridConfigurable
          rowClick="edit"
          sx={{
            "& .column-groups": {
              md: { display: "none" },
              lg: { display: "table-cell" }
            }
          }}
          omit={["id"]}
        >
          <TextField
            source="id"
            label="ID"
          />
          <ImageField 
            source="imageUrl"
            sx={{ '& img': { maxWidth: 32, maxHeight: 32, objectFit: 'contain' } }}
            label="縮圖"
          />
          <TextField
            source="memberName"
            label="隸屬會員"
            sortBy="memberId"
          />
          <TextField
            source="name"
            label="名稱"
          />
          <TruncatedTextField 
            source="description" 
            maxLength={15} 
            label="描述"
          />
          <BooleanField
            source="isPrimary"
            label="主要圖片"
          />
          <DateField 
            source="createdAt" 
            label="建立日期"  
            showTime
          />
          <TextField
            source="reviewStatusName"
            label="審核狀態"
            sortBy="reviewStatus"  // 依據 `reviewStatus` 字段來排序
          />
          <DeleteWithConfirmButton
            confirmTitle="確認刪除"
            confirmContent="您確定要刪除此Logo嗎？"
          />
        </DatagridConfigurable>
      )}
    </List>
  )
}

export default LogoList