import * as React from "react"
import { useState } from "react"
import { Box } from "@mui/material"
import LabelIcon from "@mui/icons-material/Label"

import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  useSidebarState
} from "react-admin"

import members from "../members"
import users from "../users"
import categories from '../categories';
import products from "../products";
import contacts from "../contacts";
import logos from "../logos";
import articles from "../articles";
import tags from "../tags";
import SubMenu from "./SubMenu"

const Menu = ({ dense = false }) => {
  const [state, setState] = useState({
    menuCatalog: true,
    menuSales: true,
    menuCustomers: true
  })
  const translate = useTranslate()
  const [open] = useSidebarState()

  const handleToggle = menu => {
    setState(state => ({ ...state, [menu]: !state[menu] }))
  }

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: theme =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
          })
      }}
    >
      <SubMenu
        handleToggle={() => handleToggle("menuMembers")}
        isOpen={state.menuMembers}
        // name="pos.menu.customers"
        name="會員模組"
        icon={<members.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/members"
          state={{ _scrollToTop: true }}
          // primaryText={translate(`resources.customers.name`, {
          //   smart_count: 2
          // })}
          primaryText="會員"
          leftIcon={<members.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/logos"
          state={{ _scrollToTop: true }}
          // primaryText={translate(`resources.customers.name`, {
          //   smart_count: 2
          // })}
          primaryText="LOGO"
          leftIcon={<logos.icon />}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('menuCatalog')}
        isOpen={state.menuCatalog}
        // name="pos.menu.catalog"
        name="商品管理"
        icon={<categories.icon />}
        dense={dense}
      >
        <MenuItemLink
            to="/products"
            state={{ _scrollToTop: true }}
            // primaryText={translate(`resources.categories.name`, {
            //     smart_count: 2,
            // })}
            primaryText="商品"
            leftIcon={<products.icon />}
            dense={dense}
        />
        <MenuItemLink
            to="/product-categories"
            state={{ _scrollToTop: true }}
            // primaryText={translate(`resources.categories.name`, {
            //     smart_count: 2,
            // })}
            primaryText="商品系列"
            leftIcon={<categories.icon />}
            dense={dense}
        />
      </SubMenu>
      {/* <SubMenu
        handleToggle={() => handleToggle("menuUsers")}
        isOpen={state.menuUsers}
        // name="pos.menu.customers"
        name="文章管理"
        icon={<articles.icon />}
        dense={dense}
      > */}
        {/* <MenuItemLink
          to="/articles"
          state={{ _scrollToTop: true }}
          // primaryText={translate(`resources.customers.name`, {
          //   smart_count: 2
          // })}
          primaryText="文章"
          leftIcon={<articles.icon />}
          dense={dense}
        /> */}
        {/* <MenuItemLink
          to="/categories"
          state={{ _scrollToTop: true }}
          // primaryText={translate(`resources.customers.name`, {
          //   smart_count: 2
          // })}
          primaryText="類別"
          leftIcon={<categories.icon />}
          dense={dense}
        /> */}
        {/* <MenuItemLink
          to="/tags"
          state={{ _scrollToTop: true }}
          // primaryText={translate(`resources.customers.name`, {
          //   smart_count: 2
          // })}
          primaryText="標籤"
          leftIcon={<tags.icon />}
          dense={dense}
        /> */}
      {/* </SubMenu> */}
      <SubMenu
        handleToggle={() => handleToggle("menuContacts")}
        isOpen={state.menuContacts}
        // name="pos.menu.customers"
        name="客服中心"
        icon={<contacts.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/contacts"
          state={{ _scrollToTop: true }}
          // primaryText={translate(`resources.customers.name`, {
          //   smart_count: 2
          // })}
          primaryText="聯絡表單"
          leftIcon={<contacts.icon />}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuUsers")}
        isOpen={state.menuUsers}
        // name="pos.menu.customers"
        name="帳號管理"
        icon={<users.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/users"
          state={{ _scrollToTop: true }}
          // primaryText={translate(`resources.customers.name`, {
          //   smart_count: 2
          // })}
          primaryText="帳號"
          leftIcon={<users.icon />}
          dense={dense}
        />
      </SubMenu>
    </Box>
  )
}

export default Menu