import MemberIcon from '@mui/icons-material/Person';

import MemberList from "./MemberList"
import MemberCreate from "./MemberCreate"
import MemberEdit from "./MemberEdit"

const resource = {
  list: MemberList,
  create: MemberCreate,
  edit: MemberEdit,
  icon: MemberIcon,
}

export default resource