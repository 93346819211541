import * as React from "react"
import { 
  TextInput, 
  ReferenceInput, 
  SelectArrayInput, 
  NumberInput,
  BooleanInput,
} from "react-admin"
import { useState, useEffect, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { InputAdornment, Grid, Box, Typography, Button } from "@mui/material"

const BasicInfoTab = ({prePriceRef}) => {
  const { setValue, getValues } = useFormContext();
  
  const price = useWatch({ name: "price" }) || [];

  useEffect(() => {
    if (prePriceRef.current === price) {
      return;
    }

    prePriceRef.current = price

    const quantityDiscounts = getValues('quantityDiscounts')
    quantityDiscounts.forEach((item, index) => {
      if (item?.discountPrice && price && (price - item.discountPrice) >= 0) {
        const discountPercentage = ((price - item.discountPrice) / price) * 100;
        setValue(`quantityDiscounts.${index}.discountPercentage`, discountPercentage.toFixed(2));
      } else {
        setValue(`quantityDiscounts.${index}.discountPercentage`, '');
      }
    });
  }, [price]);
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} sm={8}>
        <TextInput source="code" label="編號(可選擇手動輸入編號或由系統自動生成)" />
      </Grid>
      <Grid item xs={12} sm={4} 
        sx={{
          display: "flex",
          justifyContent: "right"
        }}
      >
        <BooleanInput label="啟用狀態" source="status" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput source="name" label="名稱" isRequired />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput source="sorting" label="排序編號" isRequired />
      </Grid>
      <Grid item xs={12} sm={12}>
        <ReferenceInput source="categories" 
          reference="product-categories" 
          sort={{ field: 'sorting', order: 'ASC' }}
        >
          <SelectArrayInput optionText="label" label="系列" isRequired />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          source="price"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
          isRequired
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput source="stock" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput source="minOrderQuantity" label="最低下訂量" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput source="maxOrderQuantity" label="最高下訂量" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput source="samplingTime" label="打樣所需時間(天)" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput source="samplingFee" label="打樣所需費用" />
      </Grid>
      <Grid item xs={12} sm={4}>
        <NumberInput source="minQuantity" label="數量選項區間最小值" isRequired />
      </Grid>
      <Grid item xs={12} sm={4}>
        <NumberInput source="maxQuantity" label="數量選項區間最大值" isRequired />
      </Grid>
      <Grid item xs={12} sm={4}>
        <NumberInput source="incrementStep" label="數量選項區間值" isRequired />
      </Grid>
    </Grid>      
  )
}

export default BasicInfoTab;