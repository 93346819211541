import * as React from "react"
import { 
  TextInput, 
  ImageInput, 
  ImageField, 
  ArrayInput, 
  SimpleFormIterator
} from "react-admin"
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Grid } from "@mui/material"

const EditableAreasTab = ({ preAreasRef }) => {
  const { setValue, getValues } = useFormContext();
  const areas = useWatch({ name: 'editableAreas' });

  useEffect(() => {
    if (preAreasRef.current === areas.length) {
      return;
    }
    
    if (preAreasRef.current > areas.length) {
      preAreasRef.current = areas.length
      return;
    }
    preAreasRef.current = areas.length

    const colors = getValues('colors')
    areas[areas.length-1].images = colors.map(color => {
      return {
        "name": color.name,
        "image": []
      }
    })
    
    setValue('editableAreas', areas)
  }, [areas]);

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} sm={12}>
        <ArrayInput source="editableAreas" label="編輯區域">
          <SimpleFormIterator getItemLabel={index => `#${index + 1}`}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextInput source="areaName" label="名稱" />
              </Grid>
              <Grid item xs={12}>
                <ImageInput 
                  source="templateImage" 
                  label="模板圖片" 
                  accept={{ 'image/*': ['.png', '.jpg'] }} 
                  sx={{
                    "& .previews": {
                      backgroundColor: "#f0f0f0",
                      padding: "10px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    },
                  }}
                >
                  <ImageField source="src" title="title" />
                </ImageInput>
              </Grid>
              <Grid item xs={12}>
                <ArrayInput source="images" label="顏色對應圖片">
                  <SimpleFormIterator getItemLabel={index => `#${index + 1}`} disableAdd disableClear disableRemove disableReordering>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextInput source="name" label="名稱" readOnly/>
                      </Grid>
                      <Grid item xs={12}>
                        <ImageInput 
                          source="image" 
                          label="對應的商品圖" 
                          accept={{ 'image/*': ['.png', '.jpg'] }}
                          sx={{
                            "& .previews": {
                              backgroundColor: "#f0f0f0",
                              padding: "10px",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                            },
                          }}
                        >
                          <ImageField source="src" title="title" />
                        </ImageInput>
                      </Grid>
                    </Grid>
                  </SimpleFormIterator>
                </ArrayInput>
              </Grid>
            </Grid>
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </Grid>
  )
}

export default EditableAreasTab;