import * as React from "react"
import { 
  TextInput, 
  ImageInput, 
  ImageField, 
  ArrayInput, 
  SimpleFormIterator
} from "react-admin"
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Grid } from "@mui/material"

const ColorOptionsTab = ({ preColorsRef }) => {
  const { setValue, getValues } = useFormContext();
  const colors = useWatch({ name: 'colors' });

  useEffect(() => {
    const updatedColors = colors.map(color => color.name)
    if (JSON.stringify(preColorsRef.current) === JSON.stringify(updatedColors)) {
      return;
    }

    preColorsRef.current = updatedColors

    const areas = getValues('editableAreas')
    for ( let i in areas ) {
      areas[i].images = updatedColors.map((color, index) => {
        return {
          ...areas[i].images[index],
          "name": color
        }
      })
    }
    setValue('editableAreas', areas)
  }, [colors]);

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} sm={12}>
        <ArrayInput source="colors" label="顏色選項">
          <SimpleFormIterator getItemLabel={index => `#${index + 1}`}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <TextInput source="name" label="名稱" />
              </Grid>
              <Grid item xs={12} sm={12}>
              <TextInput source="code" label="代碼" />
              </Grid>
            </Grid>
            <ImageInput 
              source="navImage" 
              label="圖片" 
              accept={{ 'image/*': ['.png', '.jpg'] }} 
              sx={{
                "& .previews": {
                  backgroundColor: "#f0f0f0",
                  padding: "10px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                },
              }}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput 
              source="image" 
              label="對應的商品圖" 
              accept={{ 'image/*': ['.png', '.jpg'] }} 
              sx={{
                "& .previews": {
                  backgroundColor: "#f0f0f0",
                  padding: "10px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                },
                marginTop: "1rem",
              }}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </Grid>
  )
}

export default ColorOptionsTab;