import * as React from "react"
import {
  Create,
  SimpleForm,
  TextInput,
  useTranslate,
  PasswordInput,
  email,
  BooleanInput,
  DateInput,
  TabbedForm,
  AutocompleteInput,
  ReferenceInput,
  useDataProvider
} from "react-admin"
import { useState } from "react";
import { Box, Typography, Grid, CircularProgress } from "@mui/material"

const MemberTitle = () => {
  return <span>{'會員'}</span>;
};

const MemberCreate = () => {

  const validateForm = values => {
    const errors = {}
    if (!values.lastName) {
      errors.firstName = "ra.validation.required"
    }
    if (!values.firstName) {
      errors.firstName = "ra.validation.required"
    }
    if (!values.account) {
      errors.account = "ra.validation.required"
    } else {
      const error = email()(values.account)
      if (error) {
        errors.account = error
      }
    }
    if (!values.email) {
      errors.email = "ra.validation.required"
    } else {
      const error = email()(values.email)
      if (error) {
        errors.email = error
      }
    }
    if (!values.mobile) {
      errors.mobile = "ra.validation.required"
    }
    if (!values.password) {
      errors.password = "ra.validation.required"
    }
    return errors
  }

  const CustomReferenceInput = ({reference, source, label }) => {
    const dataProvider = useDataProvider();

    const handleCreate = async (newName) => {
        try {
          const { data } = await dataProvider.create(reference, {
            data: { name: newName }
          });
          return { id: data.id, name: data.name };
        } catch (error) {
          console.error(`Error creating ${reference}:`, error);
        }
    };

    return (
      <ReferenceInput 
        source={source} 
        reference={reference}
        sort={{ field: 'createdAt', order: 'ASC' }}
      >
        <AutocompleteInput 
          label={label} 
          onCreate={handleCreate}
          isPending={true}
        />
      </ReferenceInput>
    );
  };

  return (
  <Create title={<MemberTitle/>}>
    <TabbedForm
      // Here for the GQL provider
      defaultValues={{
        code: "",
        status: true,
        lastName: "",
        firstName: "",
        account: "",
        mobile: "",
        companyName: "",
        companyRegistrationNumber: "",
        companyPhone: "",
        department: "",
        title: "",
        password: ""
      }}
      validate={validateForm}
    >
      <TabbedForm.Tab
        label="基本資料"
        sx={{ maxWidth: "50em" }}
      >
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={8}>
            <TextInput source="code" label="編號" disabled />
          </Grid>
          <Grid item xs={12} sm={4} 
            sx={{
              display: "flex",
              justifyContent: "right"
            }}
          >
            <BooleanInput label="啟用狀態" source="status" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source="lastName" label="姓氏" isRequired autoComplete="off" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source="firstName" label="名字" isRequired autoComplete="off" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput type="account" source="account" label="帳號" isRequired autoComplete="off" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PasswordInput source="password" label="密碼" isRequired autoComplete="new-password" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source="email" label="電子郵件" isRequired autoComplete="off" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source="mobile" label="手機" isRequired autoComplete="off" />
          </Grid>
          <Grid item xs={12}>
            <TextInput source="description"
              label="備註" 
              multiline
              rows={4} // 設定多行的行數
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <PasswordInput source="confirmPassword" label="再次輸入密碼" isRequired autoComplete="new-password" />
          </Grid> */}
        </Grid>
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="公司資訊"
        path="company"
        sx={{ maxWidth: "50em" }}
      >
        <Grid container columnSpacing={2}>
          <Grid item xs={12}>
            <TextInput source="companyName" label="公司名稱" />
          </Grid>
          <Grid item xs={12} sm={6}>
            < CustomReferenceInput label="公司規模" source="companySizeId" reference="company-sizes" />
          </Grid>
          <Grid item xs={12} sm={6}>
            < CustomReferenceInput label="產業別" source="industryId" reference="industries" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source="companyRegistrationNumber" label="公司統編" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source="companyPhone" label="公司電話" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source="department" label="部門" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source="title" label="職稱" />
          </Grid>
        </Grid>
      </TabbedForm.Tab>
    </TabbedForm>
  </Create>
  )
}

export default MemberCreate