import * as React from "react"
import { 
  Create, 
  TabbedForm, 
  TextInput, 
  required, 
  ImageInput, 
  ImageField, 
  ArrayInput, 
  SimpleFormIterator, 
  FileInput, 
  FileField, 
  ReferenceInput, 
  SelectArrayInput, 
  NumberInput,
  BooleanInput,
  useSourceContext
} from "react-admin"
import { useState, useEffect, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { InputAdornment, Grid, Box, Typography, Button } from "@mui/material"

import BasicInfoTab from "./BasicInfoTab"
import ColorOptionsTab from "./ColorOptionsTab"
import EditableAreasTab from "./EditableAreasTab"
import QuantityDiscountsTab from "./QuantityDiscountsTab"

const ProductTitle = () => {
  return <span>{'商品'}</span>;
};

const validateForm = values => {
  const errors = {}
  if (!values.name) {
    errors.name = "ra.validation.required"
  }
  if (values.categories.length === 0) {
    errors.categories = "ra.validation.required"
  }
  if (!values.coverImage.rawFile) {
    errors.coverImage = "ra.validation.required"
  }
  if (!values.templateImage.rawFile) {
    errors.templateImage = "ra.validation.required"
  }
  if (!values.price) {
    errors.price = "ra.validation.required"
  }
  if (!values.minQuantity) {
    errors.minQuantity = "ra.validation.required"
  }
  if (!values.maxQuantity) {
    errors.maxQuantity = "ra.validation.required"
  }
  if (!values.incrementStep) {
    errors.incrementStep = "ra.validation.required"
  }
  if (!values.specifications) {
    errors.specifications = "ra.validation.required"
  }
  if (values.specifications) {
    let validate = false;
    values.specifications.forEach((item) => {
      if (item.name) {
        validate = true;
      }
    });
    if (!validate) errors.specifications = "ra.validation.required";
  }
  if (values.editableAreas) {
    errors.editableAreas = [];
  
    values.editableAreas.forEach((area, index1) => {
      if (area.areaName) {
        
        if (!area.templateImage || !area.templateImage.rawFile) {
          errors.editableAreas[index1] = errors.editableAreas[index1] || {}; 
          errors.editableAreas[index1].templateImage = "ra.validation.required";
        }
  
        if (area.images && Array.isArray(area.images)) {
          area.images.forEach((item, index2) => {
            if (!item.image || !item.image.rawFile) {
              errors.editableAreas[index1].images = errors.editableAreas[index1].images || [];
              errors.editableAreas[index1].images[index2] = errors.editableAreas[index1].images[index2] || {}; 
              errors.editableAreas[index1].images[index2].image = "ra.validation.required";
            }
          });
        }
      }
    });
  }
  if (values.quantityDiscounts) {
    errors.quantityDiscounts = [];
  
    values.quantityDiscounts.forEach((item, index) => {
      if (item.minQuantity && item.maxQuantity && !item.discountPrice) {
        errors.quantityDiscounts[index] = errors.quantityDiscounts[index] || {}; 
        errors.quantityDiscounts[index].discountPrice = "ra.validation.required";
      }
    });
  }
  return errors
}

const ProductCreate = () => {
  const prePriceRef = useRef(null);
  const preColorsRef = useRef(null);
  const preAreasRef  = useRef(null);
  const preDiscountsRef  = useRef(null);

  return (
  <Create title={<ProductTitle/>}>
    <TabbedForm 
      defaultValues={{
        sorting: 1,
        code: "",
        name: "",
        categories: [],
        price: 0,
        stock: 0,
        minOrderQuantity: 1,
        maxOrderQuantity: "",
        samplingTime: "",
        samplingFee: "",
        minQuantity: 1,
        maxQuantity: 10,
        incrementStep: 1,
        description: "",
        specification: "",
        status: 1,
        coverImage: [],
        templateImage: [],
        colors: [
          {
            name: "",
            code: "",
            navImage: [],
            image: [],
          }
        ],
        specifications: [
          {
            name: "",
          }
        ],
        quantityDiscounts: [
          {
            minQuantity: "",
            maxQuantity: "",
            discountPercentage: "",
            leadTimeDays: "",
            discountPrice: "",
          }
        ],
        editableAreas: [
          {
            areaName: "",
            templateImage: "",
            images: [
              {
                name: "",
                image: [],
              }
            ]
          }
        ]
      }}
      validate={validateForm}
    >
      <TabbedForm.Tab
        label="resources.products.tabs.image"
        sx={{ maxWidth: "50em" }}
      >
        <ImageInput 
          source="coverImage" 
          label="封面圖片" 
          accept={{ 'image/*': ['.png', '.jpg'] }} 
          sx={{
            "& .previews": {
              backgroundColor: "#f0f0f0", // 外層容器灰底
              padding: "10px", // 圖片周圍留白
              borderRadius: "4px", // 圓角效果
              border: "1px solid #ccc", // 灰色邊框
            },
          }}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput 
          source="templateImage" 
          label="模板圖片" 
          accept={{ 'image/*': ['.png', '.jpg'] }} 
          sx={{
            "& .previews": {
              backgroundColor: "#f0f0f0",
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            },
            marginTop: "1rem",
          }}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput 
          source="otherImage" 
          label="其他圖片" 
          accept={{ 'image/*': ['.png', '.jpg'] }} 
          multiple 
          sx={{
            "& .previews": {
              backgroundColor: "#f0f0f0",
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            },
            marginTop: "1rem",
          }}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="基本資料"
        path="basic"
        sx={{ maxWidth: "50em" }}
      >
        <BasicInfoTab prePriceRef={prePriceRef} />
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="顏色選項"
        path="color-options"
        sx={{ maxWidth: "50em" }}
      >
        <ColorOptionsTab preColorsRef={preColorsRef} />
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="規格選項"
        path="specification-options"
        sx={{ maxWidth: "50em" }}
      >
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={12}>
            <ArrayInput source="specifications" label="規格選項">
              <SimpleFormIterator getItemLabel={index => `#${index + 1}`}> 
                <TextInput source="name" label="名稱" inputProps={{ maxLength: 20 }} />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </Grid>
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="數量折扣規則"
        path="quantity-discounts"
        sx={{ maxWidth: "50em" }}
      >
        <QuantityDiscountsTab preDiscountsRef={preDiscountsRef} />
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="編輯區域"
        path="editable-areas"
        sx={{ maxWidth: "50em" }}
      >
        <EditableAreasTab preAreasRef={preAreasRef} />
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="描述"
        path="description"
        sx={{ maxWidth: "50em" }}
      >
        <TextInput source="description"
          label="描述" 
          multiline
          rows={4} // 設定多行的行數
        />
        <TextInput source="specification"
          label="詳細規格" 
          multiline
          rows={8} // 設定多行的行數
        />
      </TabbedForm.Tab>
    </TabbedForm>
  </Create>
  )
}

export default ProductCreate