import LogoIcon from '@mui/icons-material/PhotoLibrary';

import LogoList from "./LogoList"
import LogoCreate from "./LogoCreate"
import LogoEdit from "./LogoEdit"

const resource = {
  list: LogoList,  
  create: LogoCreate,
  edit: LogoEdit,
  icon: LogoIcon,
}

export default resource