import axios from 'axios';

const authProvider = {
  login: ({ account, password }) => {
    return axios.post(
          `${process.env.REACT_APP_API_URL}/auth/login`, 
          { account, password, appType: 'backend' },
          {
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY
            }
          }
        )
        .then(response => {
            const resp = response.data;
            if (resp.status === 'success') {
              const data = resp.data;
              const expiresAt = Date.now() + data.accessExpiresIn * 1000;

              localStorage.setItem('auth_token', data.accessToken);
              localStorage.setItem('refresh_token', data.refreshToken);
              localStorage.setItem('identity', JSON.stringify(data.user));
              localStorage.setItem('auth_expires_at', expiresAt.toString());
            }
        })
        .catch(error => {
            throw new Error(error.response.data.message || 'Login failed');
        });
  },
  logout: async () => {
    localStorage.removeItem("auth_token")
    localStorage.removeItem("refresh_token")
    localStorage.removeItem("identity")
    localStorage.removeItem("auth_expires_at")

    return Promise.resolve()
  },
  checkError: async ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem("auth_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("identity");
      localStorage.removeItem("auth_expires_at");

      return Promise.reject({ redirectTo: "/login" });
    }
    return Promise.resolve();
  },
  checkAuth: async () => {
    // 允許訪問 /health 路徑，其他情況則正常執行認證檢查
    if (window.location.pathname === "/health") {
      return Promise.resolve();
    }
    const authToken = localStorage.getItem("auth_token");
    if (!authToken) {
      return Promise.reject()
    }
    if (isTokenExpired()) {
      const refreshed = await refreshAccessToken();
      if (!refreshed) {
        localStorage.removeItem("auth_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("identity");
        localStorage.removeItem("auth_expires_at");

        return Promise.reject({ redirectTo: "/login" });
      }
    }
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
  getIdentity: () => {
    const identity = localStorage.getItem('identity');
    if (identity) {
      const user = JSON.parse(identity);
      return Promise.resolve({
        id: user.id,
        fullName: user.name,
        avatar: user.avatar || '' 
      })
    }
  }
}

// 檢查Token過期狀況
const isTokenExpired = () => {
  const expiresAt = localStorage.getItem("auth_expires_at");
  return expiresAt ? Date.now() >= parseInt(expiresAt) : true;
};

// 重新獲得訪問令牌
const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refresh_token');
  if (!refreshToken) {
    console.error('No refresh token found');
    return false;
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/refresh`,
      {}, // POST body
      {
        headers: {
          'Authorization': `Bearer ${refreshToken}`,
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
      }
    );

    const { status, data } = response.data;
    if (status !== 'success') {
      console.error('Failed to refresh access token');
      return false;
    }
    const expiresAt = Date.now() + data.expiresIn * 1000;

    localStorage.setItem('auth_token', data.accessToken);
    localStorage.setItem('auth_expires_at', expiresAt.toString());
    return true;
  } catch (error) {
    console.error('Error refreshing access token:', error.message);
    return false;
  }
}

export default authProvider
