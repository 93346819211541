import * as React from "react"
import { 
  ArrayInput, 
  NumberInput,
  SimpleFormIterator
} from "react-admin"
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Grid } from "@mui/material"

const QuantityDiscountsTab = ({preDiscountsRef}) => {
  const { setValue, getValues } = useFormContext();

  const quantityDiscounts = useWatch({ name: "quantityDiscounts" }) || [];

  useEffect(() => {
    const updatedDiscountPrice = quantityDiscounts.map(discount => discount.discountPrice)
    if (JSON.stringify(preDiscountsRef.current) === JSON.stringify(updatedDiscountPrice)) {
      return;
    }

    preDiscountsRef.current = updatedDiscountPrice

    const originalPrice = getValues('price')
    quantityDiscounts.forEach((item, index) => {
      if (item?.discountPrice && originalPrice && (originalPrice - item.discountPrice) >= 0) {
        const discountPercentage = ((originalPrice - item.discountPrice) / originalPrice) * 100;
        setValue(`quantityDiscounts.${index}.discountPercentage`, discountPercentage.toFixed(2));
      } else {
        setValue(`quantityDiscounts.${index}.discountPercentage`, 0);
      }
    });
  }, [quantityDiscounts]);

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} sm={12}>
        <ArrayInput source="quantityDiscounts" label="數量折扣規則">
          <SimpleFormIterator inline getItemLabel={index => `#${index + 1}`}> 
            <NumberInput source="minQuantity" label="最小購買數量" />
            <NumberInput source="maxQuantity" label="最大購買數量" />
            <NumberInput source="discountPercentage" label="折扣百分比" readOnly />
            <NumberInput source="discountPrice" label="折扣價" />
            <NumberInput source="leadTimeDays" label="交期天數" />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </Grid>
  )
}

export default QuantityDiscountsTab;