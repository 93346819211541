import * as React from "react"
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    email,
    SelectInput,
    DateTimeInput,
    FullNameField,
    ImageInput,
    ImageField,
    SimpleFormIterator,
    ArrayInput,
    ReferenceInput,
    BooleanInput,
    NumberInput,
    useDataProvider,
    useNotify,
    TabbedForm
  } from "react-admin"
import { Box, Typography, Grid } from "@mui/material"
import { useWatch, useFormContext } from 'react-hook-form';
import { useEffect, useRef } from "react"

const LogoTitle = () => {
  return <span>{'LOGO'}</span>
}

const LogoEdit = () => {
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const prevFileRef = useRef(null);

  const validateForm = async (values) => {
    const errors = {}
  
    if (!values.name) {
      errors.name = "ra.validation.required"
    }
    if (!values.memberId) {
      errors.email = "ra.validation.required"
    }
    if (!values.logoImage?.rawFile && !values.logoImage?.[0]?.title) {
      errors.logoImage = "ra.validation.required"
    }

    try {
      if (values.isPrimary) {
        const { data } = await dataProvider.getList("logos", {
          filter: { memberId: values.memberId, isPrimary: true, excludeId: values.id },
          pagination: { page: 1, perPage: 1 },
          sort: { field: "createdAt", order: "ASC" },
        });
        if (data.length > 0) {
          errors.isPrimary = "resources.members.errors.default_exists"
        }
      }
    } catch (error) {
      console.error("驗證預設LOGO圖片錯誤:", error);
    }

    return errors
  }

  const CustomImageInput = () => {
      const { setValue } = useFormContext();
      const uploadedImage = useWatch({ name: 'logoImage' });

      useEffect(() => {
        if (!uploadedImage?.rawFile) {
          if (prevFileRef.current) {
            setValue("colors", [{
              colorHex: "",
              c: "",
              m: "",
              y: "",
              k: "",
            }]);
            prevFileRef.current = null
          }
          return;
        };
        
        if (prevFileRef.current === uploadedImage.rawFile) {
          return;
        }
        prevFileRef.current = uploadedImage.rawFile;

        const fetchPantoneColor = async () => {
          try {
            const { data } = await dataProvider.getPantoneColor("logos/pantone-color", { 
              data: { logoImage: uploadedImage } 
            });

            if (data) {
              setValue("colors", data.map(item => ({
                colorHex: item,
                c: '',
                m: '',
                y: '',
                k: '',
              })));
            }
          } catch (error) {
            console.error("取得 Pantone 顏色錯誤：", error);
          }
        };
      
        fetchPantoneColor();
      }, [uploadedImage?.rawFile]);

      return (
        <ImageInput 
          source="logoImage" 
          label="圖片" 
          accept={{ 'image/*': ['.png'] }}
          >
          <ImageField source="src" title="title" />
        </ImageInput>
      );
  };

  return (
    <Edit title={<LogoTitle/>} >
      <TabbedForm
        // Here for the GQL provider
        defaultValues={{
          memberId: "",
          name: "",
          description: "",
          isPrimary: false,
          reviewStatus: "pending",
          logoImage: [],
          colors: [
            {
              colorHex: "",
              c: "",
              m: "",
              y: "",
              k: "",
            },
          ]
        }}
        validate={validateForm}
      >
        <TabbedForm.Tab
          label="基本資料"
          sx={{ maxWidth: "50em" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomImageInput />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextInput autoFocus source="name" label="名稱" isRequired />
            </Grid>
            <Grid item xs={12} sm={4}
              sx={{
                display: "flex",
                justifyContent: "right"
              }}
            >
              <BooleanInput label="主要圖片" source="isPrimary" />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <ReferenceInput source="memberId"
                reference="members"
                sort={{ field: 'createdAt', order: 'ASC' }}
                >
                <SelectInput label="隸屬會員" optionText="name" isRequired/>
              </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectInput source="reviewStatus" 
                label="審核狀態" 
                isRequired
                choices={[
                  { id: 'pending', name: '待審核' },
                  { id: 'approved', name: '審核通過' },
                  { id: 'rejected', name: '審核未通過' }
                ]} 
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput source="description"
                label="描述" 
                multiline
                rows={4} // 設定多行的行數
              />
            </Grid>
        </Grid>
        </TabbedForm.Tab>
        <TabbedForm.Tab
          label="顏色色號"
          path="colors"
          sx={{ maxWidth: "50em" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <ArrayInput source="colors" label="顏色色號">
                <SimpleFormIterator getItemLabel={(index) => `#${index + 1}`}>
                  {/* HEX 欄位 */}
                  <TextInput source="colorHex" label="HEX" sx={{ display: 'none' }} />
                  {/* CMYK 欄位（排成同一列） */}
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center", width: '100%' }}>
                    <NumberInput source="c" label="C" sx={{ width: '25%' }} />
                    <NumberInput source="m" label="M" sx={{ width: '25%' }} />
                    <NumberInput source="y" label="Y" sx={{ width: '25%' }} />
                    <NumberInput source="k" label="K" sx={{ width: '25%' }} />
                  </Box>
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          </Grid>
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  )
}

export default LogoEdit